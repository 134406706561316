var page_loaded = 1;
global.page_loaded = page_loaded;

page = {
	load: function(){
		this.fillGrid();
		this.settSlider();
	},
	fillGrid: function(){
	    if ($(window).outerWidth(true) > 1200) {
	      var numCol = 3;
	    } else if ($(window).outerWidth(true) > 767 && $(window).outerWidth(true) < 1199){
	      var numCol = 3;
	    } else {
	      var numCol = 2;
	    }
	    
	    /* Calcolo le colonne vuote e faccio un append alla gallery per mantenere space-between */
	    $('.homeNews__wrap').each(function(){
	      var itemCount = $('.homeNews__block').length,
	        resto = (itemCount % numCol),
	        cols = numCol - resto;
	      for (var i = 0; i < cols; i++) {
	        $(this).append('<div class="homeNews__block"></div>');
	      }
	    })
	},
	settSlider: function(){
	    jQuery('.gallery').each(function(){    
			jQuery(this).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				dots: false,
				speed: 500,
				touch: false,
				autoplay: true,
				autoplaySpeed: 5000
			});
	    })
	}, 
}


$(window).on('load', function(){
	page.load();
})